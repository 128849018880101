
import { ReactComponent as Logo } from "./images/miha_w_name.svg";

function Main() {
  return (

    <div className="flex flex-col-reverse mt-25dvh mb-20dvh md:items-center md:grid md:min-h-70dvh md:grid-cols-2 md:mb-auto md:mt-14" id="main">
      <div className="text-center pt-24 text-3xl md:text-6xl md:pt-0 front_font">
        <p>
          Developer with
        </p>
        <p>premium sales background
        </p>
      </div>
      <div>
        <Logo className="fill-white mx-auto w-50dvw md:w-30dvw lg:w-25dvh h-auto max-w-xl" />
      </div>


    </div>

  );
}

export default Main;