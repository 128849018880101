import timeCalc from './components/timeCalc';

function About() {
  return (
    <div className='py-5 md:pt-14' id='about'>
      <h2 className='py-5'>About Me</h2>
        <div className='my-5 pb-10 pt-5 px-5 rounded-3xl grid-bg shadow-lg shadow-pink-950'>
          
          <h3 className='py-5'>ITC engineer with sales background</h3>
          <p>
          I'm a {timeCalc(new Date('1985-09'))}-year-old guy with a passion for technology and a degree in ICT engineering. My past career includes over {timeCalc(new Date('2009-03'))} years of experience in sales with premium brands like Audi and Puustelli, so I'm no stranger to working with people and delivering results.
          </p>
          <h3 className='py-5 text-pretty'>Transitioning to IT</h3>
          <p>
          I'm currently transitioning to the IT sector and am thrilled to bring my skills and experience to a new field that I have been passionate about since I was a child. I have experience working with Java, Python, JS/TS, React, C-languages and Linux servers. Future goals include mastering Rust, Python and TypeScript and am very excited to explore new opportunities in the IT sector.
          </p>
          <h3 className='py-5'>This page</h3>
          <p>
          I host this page on my little Raspberry Pi server, hardened with love and taken care daily. I'm using React, TypeScript, TailwindCSS to make this page tik. This page acts as my playground so don't be surprised if you see some weird stuff here.
          </p>
        </div>
    </div>
  );
}

export default About;

