import { ProjectsItem } from "../types/ProjectsItemType";

const ProjectsGrid = ({ projects }: { projects: ProjectsItem[] }) => {
    return (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {projects.map((project: ProjectsItem, index) => (
                <div className="p-5 rounded-3xl grid-bg shadow-md shadow-pink-950">
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                </div>
            ))}
        </div>
    );
};

export default ProjectsGrid;