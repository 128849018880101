
function ProjectsOutro() {
    return (
        <div className="my-5 py-10 px-5 rounded-3xl grid-bg shadow-lg shadow-pink-950">
            <p>These projects have not only enriched my technical skills but also deepened my understanding of software development principles, system architecture, and best practices in both development and operations.
            Each project presented unique challenges and opportunities for growth, contributing to my journey as a versatile and adaptable professional in the field of technology.</p>
        </div>
    );
}

export default ProjectsOutro;
