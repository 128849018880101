import ProjectsGrid from './components/ProjectsGrid';
import ProjectsList from './texts/projects_txt';
import ProjectsIntro from './components/ProjectsIntro';
import ProjectsOutro from './components/ProjectsOutro';

function Projects() {
  return (
    <div className="py-5 min-h-96 md:pt-20 " id="projects">

      <ProjectsIntro />

      <ProjectsGrid projects={ProjectsList} />

      <ProjectsOutro />

    </div>
  );
}

export default Projects;
