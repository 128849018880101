import { ReactComponent as Logo } from './images/miha_wide.svg';

function Nav() {
  return (
    <div className='fixed bottom-0 front_font mx-0 w-dvw shadow-inner shadow-pink-950 bg-pink-600 md:w-auto md:bg-transparent md:sticky md:top-5 md:bottom-auto md:mx-12 lg:mx-15dvw md:shadow-none'> 
      <header className='z-20 text-white text-xl justify-center flex md:justify-between py-3 md:text-2xl md:bottom-auto'>
          <Logo className='h-8 w-auto ml-5 hidden fill-pink-600 md:block' />
          <nav className=''>
            <ul className='flex items-center gap-5 bg-opacity-85 lg:ml-15dvw md:px-5  lg:gap-10 md:bg-pink-600 md:rounded-2xl md:shadow-inner md:shadow-pink-950 '>
              <li className='hover:text-rose-950 active:text-sky-400 transition-all'> <a href="#main">Main</a></li>
              <li className='hover:text-rose-950 active:text-sky-400 transition-all'><a href="#about">About</a></li>
              <li className='hover:text-rose-950 active:text-sky-400 transition-all'><a href="#projects">Projects</a></li>
            </ul>
          </nav>
          <a href="https://www.linkedin.com/in/mikko-hanninen/" target="_blank" className='bg-opacity-85 transition-all ml-5 md:ml-0 md:shadow-inner hover:text-rose-950 active:text-sky-400  md:bg-pink-600 md:rounded-2xl md:shadow-pink-950 
                          md:px-5 md:mr-5'>Contact</a>
      </header>
    </div>
  );
}


export default Nav;