import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import About from './About';
import Nav from './Nav';
import Projects from './Projects';
import Main from './Main';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <Nav />

    <div className="overflow-y-auto mx-2 text-white md:mx-12 lg:mx-15dvw">

      <Main />
      <About />
      <Projects />

      <footer className="text-center text-white mb-8 text-s py-10">
        © Copyright 2024, Mikko Hänninen
      </footer>

    </div>
  </React.StrictMode>
);
