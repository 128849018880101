
function ProjectsIntro() {
    return (
        <div>
            <h2>Project Portfolio: Software Development and System Administration</h2>
            <div className="my-5 py-20 px-5 rounded-3xl grid-bg shadow-md shadow-pink-950">
                <div>
                    <p>In my journey as a software developer and system administrator, I've had the opportunity to work on a diverse range of projects spanning various programming languages and system environments. Below are highlights of some of my key projects:</p>
                </div>
            </div>
        </div>
    );
}

export default ProjectsIntro;
