
function timeCalc(startDate: Date): number {
  const today = new Date();

  let years = today.getFullYear() - startDate.getFullYear();
  const month = today.getMonth() - startDate.getMonth();
  if (month < 0) {
    years--;
  }
  return years;
}

export default timeCalc;