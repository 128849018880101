import { ProjectsItem } from "../types/ProjectsItemType";

const projects: ProjectsItem[] = [
    {
        title: "Java / JavaFX",
        description: "Developed a scalable web application using Java frameworks for backend services, ensuring efficiency in handling user requests. Created JavaFX desktop applications to take care of daily tasks."
    },
    {
        title: "Python",
        description: "Used Python for backend development, creating RESTful APIs and web services. Developed Python scripts for automating repetitive tasks and visualization."
    },
    {
        title: "JavaScript / TypeScript",
        description: "Contributed to frontend development using modern JavaScript and TypeScript frameworks like React, crafting dynamic user interfaces with gsap and enhancing user experiences."
    },
    {
        title: "React / TailwindCSS / GSAP",
        description: "Done many React-based web application, orchestrating the frontend architecture and implementing modern UI/UX design principles."
    },
    {
        title: "C, C++ and Rust",
        description: "Engaged in low-level systems programming using C and C++ and eager to move to new Rust implementations."
    },
    {
        title: "Linux Server Administration",
        description: "This website runs on a little Raspberry Pi Linux server. Configuring, hardening and maintaining servers for hosting web applications, databases, and other critical services, ensuring optimal performance and uptime."
    }
];

export default projects;
